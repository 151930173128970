<template>
  <div class="pageContol listWrap templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">模拟考成绩查询</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">名单详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="练习时间" class="searchboxItem ci-full">
              <span class="itemLabel">练习时间:</span>
              <el-date-picker v-model="retrievalData.practiceTime" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <div title="成绩" class="searchboxItem ci-full">
              <span class="itemLabel">成绩:</span>
              <el-input v-model="retrievalData.gradeStart" @keyup.native="retrievalData.gradeStart = zF.oninput2(retrievalData.gradeStart, 2
              )" type="text" size="small" placeholder="请输入最小成绩" clearable />
              <span style="min-width: 30px;">至</span>
              <el-input v-model="retrievalData.gradeEnd"  @keyup.native="retrievalData.gradeEnd = zF.oninput2(retrievalData.gradeEnd, 2
              )" type="text" size="small" placeholder="请输入最大成绩" clearable />
            </div>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="getData()">查询</el-button>
            <el-button class="bgc-bv" style="margin: 0 10px" round @click="exportData()">导出</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" fixed />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" minWidth="200px" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" minWidth="200px" />
              <el-table-column label="总成绩" align="left" show-overflow-tooltip prop="score" minWidth="100" />
              <el-table-column label="答题数量" align="left" show-overflow-tooltip prop="answerNum" minWidth="100" />
              <el-table-column label="正确题数" align="left" show-overflow-tooltip prop="successNum" minWidth="100" />
              <el-table-column label="错误题数" align="left" show-overflow-tooltip prop="errorNum" minWidth="100" />
              <el-table-column label="练习时间" align="left" show-overflow-tooltip minWidth="200px">
                <template slot-scope="scope">
                  {{
                    scope.row.createTimeStr | momentDate
                  }}
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "inkQuestionBank_modelExamScoreQuery_modelExamScoreQueryList",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      // 父页面数据
      lastPageData: {
        mockId: "", // 模拟考试id
        paperId: "", // 试卷id
      },
      // 检索数据
      retrievalData: {
        userName: "", // 姓名
        practiceTime: [], // 练习时间
        gradeStart: undefined, // 成绩最小分
        gradeEnd: undefined, // 成绩最大分
      },
    };
  },
  mixins: [List],
  created() {
    this.lastPageData.mockId = this.$route.query.mockId;
    this.lastPageData.paperId = this.$route.query.paperId;
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        mockId: this.$route.query.mockId,
        paperId: this.$route.query.paperId,
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.practiceTime) {
        params.createTimeStart = this.retrievalData.practiceTime[0];
        params.createTimeEnd = this.retrievalData.practiceTime[1];
      }
      if ((!this.retrievalData.gradeStart && this.retrievalData.gradeEnd) || (this.retrievalData.gradeStart && !this.retrievalData.gradeEnd)) {
        this.$message.error("请补全成绩起止分数查询");
        return;
      } else
        if ((this.retrievalData.gradeStart && this.retrievalData.gradeEnd) && Number(this.retrievalData.gradeStart) > Number(this.retrievalData.gradeEnd)) {
          this.$message.error("结束成绩应大于等于开始成绩");
          return;
        } else {
          if (this.retrievalData.gradeStart) {
            params.gradeStart = this.retrievalData.gradeStart;
          }
          if (this.retrievalData.gradeEnd) {
            params.gradeEnd = this.retrievalData.gradeEnd;
          }
        }
      this.doFetch({
        url: "/biz/exam/bank/mock/scorePersonPagerList",
        params,
        pageNum
      }, true, 6);
    },
    // 导出
    exportData() {
      let params = {
        mockId: this.$route.query.mockId,
        paperId: this.$route.query.paperId,
      };
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.practiceTime) {
        params.createTimeStart = this.retrievalData.practiceTime[0];
        params.createTimeEnd = this.retrievalData.practiceTime[1];
      }
      if (this.retrievalData.gradeStart) {
        params.gradeStart = this.retrievalData.gradeStart;
      }
      if (this.retrievalData.gradeEnd) {
        params.gradeEnd = this.retrievalData.gradeEnd;
      }
      this.$post("/biz/exam/bank/mock/scorePerson/export", params, 3000, true, 6).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          for (let item of list) {
            console.log(item);
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(res.message);
        }
      })
    },
  },
};
</script>

<style lang="less" scoped></style>